<template>
  <nuxt-link
    v-if="locale !== 'sv'"
    class="startpage-rolfs_info-grouptravel px-4"
    :to="localeURLs['info-grouptravel']"
  >
    <MediaWithText
      class="w-full"
      :critical="true"
      :media="{ location: imageByLocale, mediatype: 'image' }"
      t-title="frontPage.pitchTitle"
      t-subtitle="frontPage.pitchSubtitle"
      t-ending-text="frontPage.pitchBy"
    >
      <template #description>
        <p
          class="mb-4"
          data-i18n="frontPage.pitchDescriptionRow1"
        >
          {{ $t('frontPage.pitchDescriptionRow1') }}
        </p>
        <p
          class="mb-4"
          data-i18n="frontPage.pitchDescriptionRow2"
        >
          {{ $t('frontPage.pitchDescriptionRow2') }}
        </p>
      </template>
    </MediaWithText>
    <div class="flex justify-center mt-6">
      <button class="btn btn-lg btn-blue bg-medium-blue rounded-3xl normal-case">
        <h4 class="mb-0 font-normal">
          {{ $t('frontPage.pitchButton') }}
        </h4>
      </button>
    </div>
  </nuxt-link>
</template>

<script>
import { mapState } from 'pinia'

export default defineNuxtComponent({
  setup () {
    const { urls: localeURLs } = useLocale()

    return {
      localeURLs,
    }
  },

  computed: {
    ...mapState(useLocaleStore, ['locale']),

    imageByLocale () {
      return this.locale === 'sv' ? 'campaign/rolfs-grouptravel-banner' : 'shutterstock_2399743963_s3xpaq'
    },
  },
})
</script>

<style lang="scss" scoped>

</style>
